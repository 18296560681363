
import {computed, defineComponent, PropType, ref} from 'vue';
import useContactPersons from '../../composeables/useContactPersons';
import {IReviewData} from '../../models/ReviewData';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import useCurrentUser from '../../composeables/useCurrentUser';
import useActiveTask from '../../composeables/useActiveTask';

export default defineComponent({
    name: 'SelectReceivingCoordinatorsField',
    components: {ProgressSpinner},
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    },
    emits: ['success'],
    setup(props) {
        const isReady = ref(false);
        const selectedChoices = ref<boolean[]>([]);
        const panelIndex = ref<number>();

        useContactPersons().loadContactPersons(props.data.id).then(() => {
            if (props.data.receiving_coordinators) {
                const persons = useContactPersons().contactPersons.value;

                for (let i = 0; i < persons.length; i++) {
                    for (const coordinator of props.data.receiving_coordinators) {
                        if (persons[i]?.email.toLowerCase() === coordinator.email.toLowerCase()) {
                            selectedChoices.value[i] = true;
                            panelIndex.value = i;
                        }
                    }
                }
            }

            isReady.value = true;
        });

        return {
            user: useCurrentUser().user,
            selectedEmails: computed(() => {
                const selectedPersons = [];
                const persons = useContactPersons().contactPersons.value;

                for (let i = 0; i < persons.length; i++) {
                    if (selectedChoices.value[i]) {
                        selectedPersons.push(persons[i].email.toLowerCase());
                    }
                }

                return selectedPersons;
            }),
            contactPersons: useContactPersons().contactPersons,
            onUpdate: () => {
                if (selectedChoices.value?.length) {
                    useActiveTask().setSubmitTaskPrompt(true);
                }
            },
            isReady,
            selectedChoices
        }
    }
})
