import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "message-row message-view" }
const _hoisted_2 = {
  key: 0,
  class: "message-view__item"
}
const _hoisted_3 = { class: "h4" }
const _hoisted_4 = { class: "organization mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_6 = ["datetime"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_DynamicTaskDisplay = _resolveComponent("DynamicTaskDisplay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "slide-fade-up" }, {
      default: _withCtx(() => [
        (_ctx.task)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.task.title), 1),
              _createElementVNode("div", _hoisted_4, [
                _cache[2] || (_cache[2] = _createTextVNode(" Dashboard ")),
                _createVNode(_component_RouterLink, {
                  to: {name: 'PeerReviewSingle', params: {id: _ctx.task.reviewId}}
                }, {
                  default: _withCtx(() => [
                    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "oif oif-url mr-1" }, null, -1)),
                    _createTextVNode(_toDisplayString(_ctx.task.partnershipName), 1)
                  ]),
                  _: 1
                }, 8, ["to"]),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                (_ctx.task.recipientPartnershipName)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      _cache[1] || (_cache[1] = _createTextVNode("Voor: ")),
                      _createElementVNode("i", null, _toDisplayString(_ctx.task.recipientPartnershipName), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("time", {
                class: "d-inline-block mb-2",
                datetime: _ctx.task.createdAt
              }, _toDisplayString(_ctx.task.createdAt), 9, _hoisted_6),
              _createElementVNode("p", {
                innerHTML: _ctx.task.content
              }, null, 8, _hoisted_7),
              _createVNode(_component_DynamicTaskDisplay, { task: _ctx.task }, null, 8, ["task"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}