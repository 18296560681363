import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col col-12" }
const _hoisted_3 = { class: "form-check" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "row mb-3" }
const _hoisted_6 = { class: "col col-12" }
const _hoisted_7 = { class: "form-check" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "row mb-3" }
const _hoisted_10 = { class: "col col-12" }
const _hoisted_11 = { class: "form-check" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentUploadField = _resolveComponent("DocumentUploadField")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("input", {
            type: "checkbox",
            class: "form-check-input",
            name: "task1",
            disabled: _ctx.disabled
          }, null, 8, _hoisted_4),
          _cache[0] || (_cache[0] = _createElementVNode("label", { class: "form-check-label" }, "Instructies (o.a. over notuleren)", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("input", {
            type: "checkbox",
            class: "form-check-input",
            name: "task2",
            disabled: _ctx.disabled
          }, null, 8, _hoisted_8),
          _cache[1] || (_cache[1] = _createElementVNode("label", { class: "form-check-label" }, "Agenda opstellen", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("input", {
            type: "checkbox",
            class: "form-check-input",
            name: "task3",
            disabled: _ctx.disabled
          }, null, 8, _hoisted_12),
          _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-check-label" }, "Locatie & catering regelen", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _cache[3] || (_cache[3] = _createElementVNode("label", null, "Voorbereidingsdocumenten", -1)),
        _createVNode(_component_DocumentUploadField, {
          name: "preparation_document",
          disabled: _ctx.disabled,
          "current-document": _ctx.data.preparation_document
        }, null, 8, ["disabled", "current-document"])
      ])
    ])
  ]))
}