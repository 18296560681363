import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-2 invite-field mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "badge badge-secondary p-1"
}
const _hoisted_3 = {
  key: 1,
  class: "badge badge-secondary p-1"
}
const _hoisted_4 = {
  key: 0,
  class: "form-check mb-1"
}
const _hoisted_5 = ["checked", "disabled"]
const _hoisted_6 = {
  class: "form-check-label",
  for: "inviteResponse1"
}
const _hoisted_7 = { class: "form-check" }
const _hoisted_8 = ["checked", "disabled"]
const _hoisted_9 = {
  class: "form-check-label",
  for: "inviteResponse2"
}
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 1,
  class: "form-group mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LimitedTextArea = _resolveComponent("LimitedTextArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.invite.sendingPartnership.label), 1),
    (!_ctx.invite.isAcceptable && (_ctx.invite.status === 'pending'))
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("b", null, "Deze uitnodiging kan niet worden geaccepteerd:", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.invite.reasonForBeingUnacceptable), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.invite.trashed && (_ctx.invite.status !== 'rejected'))
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _cache[4] || (_cache[4] = [
          _createElementVNode("b", null, "Deze uitnodiging kan niet worden geaccepteerd:", -1),
          _createElementVNode("br", null, null, -1),
          _createTextVNode(" De uitnodiging is ingetrokken. ")
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("h6", null, "Hierbij bevestig ik dat " + _toDisplayString(_ctx.invite.invitedPartnership.label) + ":", 1),
      (!_ctx.invite.trashed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("input", {
              id: "inviteResponse1",
              type: "radio",
              name: "inviteResponse",
              class: "form-check-input",
              value: "1",
              checked: _ctx.invite.status === 'accepted',
              disabled: _ctx.disabled || !_ctx.invite.isAcceptable,
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
            }, null, 40, _hoisted_5),
            _createElementVNode("label", _hoisted_6, [
              _cache[5] || (_cache[5] = _createElementVNode("b", null, "deelneemt", -1)),
              _createTextVNode(" aan het bezoekend panel voor de ontwikkelingsgerichte peer review van " + _toDisplayString(_ctx.invite.sendingPartnership.label) + " op " + _toDisplayString(_ctx.invite.reviewDate), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("input", {
          id: "inviteResponse2",
          name: "inviteResponse",
          type: "radio",
          class: "form-check-input",
          value: "",
          checked: _ctx.invite.status === 'rejected',
          disabled: _ctx.disabled,
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 40, _hoisted_8),
        _createElementVNode("label", _hoisted_9, [
          _cache[6] || (_cache[6] = _createElementVNode("b", null, "niet deelneemt", -1)),
          _createTextVNode(" aan het bezoekend panel voor de ontwikkelingsgerichte peer review van " + _toDisplayString(_ctx.invite.sendingPartnership.label) + " op " + _toDisplayString(_ctx.invite.reviewDate), 1)
        ])
      ]),
      _createElementVNode("input", {
        type: "hidden",
        name: "inviteId",
        value: _ctx.invite.id
      }, null, 8, _hoisted_10),
      (!_ctx.invite.trashed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _cache[7] || (_cache[7] = _createElementVNode("label", null, "Eventuele toelichting:", -1)),
            _createVNode(_component_LimitedTextArea, {
              name: "inviteResponseExplanation",
              disabled: _ctx.disabled,
              value: _ctx.invite.responseComment
            }, null, 8, ["disabled", "value"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}