
import {defineComponent, PropType, ref} from 'vue';
import {IReviewData} from '../../models/ReviewData';
import DocumentMultiUploadField from '@/form/DocumentMultiUploadField.vue';
import useActiveTask from '../../composeables/useActiveTask';
import {HtmlInputChangeEvent} from '../../helpers/HelperTypes';

export default defineComponent({
    name: 'UploadReviewDayDocumentsField',
    components: {DocumentMultiUploadField},
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    },
    emits: ['change'],
    setup(props) {
        const reviewDayStartTime = ref(props.data.peer_review_day_program?.startTime || '');
        const reviewDayEndTime = ref(props.data.peer_review_day_program?.endTime || '');
        const reviewDayLocation = ref(props.data.peer_review_day_program?.locationDetails || '');
        let hasFiles = Boolean(props.data.peer_review_day_program?.documents?.length);

        return {
            reviewDayStartTime,
            reviewDayEndTime,
            reviewDayLocation,
            onUpdate: (e: HtmlInputChangeEvent) => {
                if (e.target?.type === 'file') {
                    hasFiles = Boolean(e.target.value);
                }

                if (reviewDayStartTime.value && reviewDayLocation.value && hasFiles) {
                    useActiveTask().setSubmitTaskPrompt(true);
                }
            }
        }
    }
})
