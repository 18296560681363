import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.user?.hasWriteAccess && _ctx.canEdit)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "btn btn-primary",
        type: "button",
        disabled: !_ctx.canEdit,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)))
      }, [
        _cache[1] || (_cache[1] = _createTextVNode(" ✎ ")),
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}