import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main__inner px-1 px-lg-5" }
const _hoisted_2 = { class: "mb-5" }
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomHeading = _resolveComponent("CustomHeading")!
  const _component_PeerReviewEntry = _resolveComponent("PeerReviewEntry")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CustomHeading, { title: "Peer Reviews" }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.peerReviews)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Peer reviews voor uw organisatie", -1)),
              _createElementVNode("div", null, [
                (!_ctx.peerReviews?.owned.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Uw organistatie heeft nog geen peer reviews."))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.peerReviews.owned, (review, key) => {
                  return (_openBlock(), _createBlock(_component_PeerReviewEntry, {
                    key: key,
                    "review-name": review.title,
                    "review-id": review.id,
                    "is-complete": review.status !== 'publish'
                  }, null, 8, ["review-name", "review-id", "is-complete"]))
                }), 128))
              ])
            ]),
            (_ctx.peerReviews?.visiting.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Peer reviews als bezoekend panel", -1)),
                  _createElementVNode("div", null, [
                    (!_ctx.peerReviews?.visiting.length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Uw organisatie heeft nog geen peer reviews als bezoekend panel."))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.peerReviews.visiting, (review, key) => {
                      return (_openBlock(), _createBlock(_component_PeerReviewEntry, {
                        key: key,
                        "review-name": review.title,
                        "review-id": review.id,
                        "is-complete": review.status !== 'publish'
                      }, null, 8, ["review-name", "review-id", "is-complete"]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
    ])
  ], 64))
}