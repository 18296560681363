import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "select-second-visiting-coordinator-section"
}
const _hoisted_2 = ["onUpdate:modelValue", "value", "checked", "disabled"]
const _hoisted_3 = { class: "form-check-label" }
const _hoisted_4 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_5 = { class: "form-group mt-3" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.isReady)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactPersons, (person, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "form-check"
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": ($event: any) => ((_ctx.selectedChoices[key]) = $event),
                name: "visiting_coordinators_2[]",
                type: "checkbox",
                value: person.index,
                class: "form-check-input",
                checked: _ctx.selectedEmails.includes(person.email.toLowerCase()),
                disabled: _ctx.disabled
              }, null, 8, _hoisted_2), [
                [_vModelCheckbox, _ctx.selectedChoices[key]]
              ]),
              _createElementVNode("label", _hoisted_3, _toDisplayString(person.name) + " | " + _toDisplayString(person.email), 1)
            ]))
          }), 128))
        ]),
        (_ctx.user && _ctx.selectedEmails.length && !_ctx.selectedEmails.includes(_ctx.user.email.toLowerCase()))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
              _createTextVNode(" U heeft niet uzelf als coordinator gekozen."),
              _createElementVNode("br", null, null, -1),
              _createTextVNode(" Wanneer u deze taak voltooid "),
              _createElementVNode("b", null, "verliest u toegang", -1),
              _createTextVNode(" tot de peer review, inclusief deze taak. ")
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("label", null, "De coördinator die deelneemt aan het panel:", -1)),
          _createElementVNode("select", {
            name: "visiting_coordinator_on_panel_2",
            class: "form-control",
            disabled: _ctx.disabled || !_ctx.selectedChoices.length
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("option", { value: "" }, "(Er neemt niemand deel aan het panel)", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactPersons, (person, key) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                (_ctx.selectedChoices[key])
                  ? (_openBlock(), _createElementBlock("option", {
                      key: 0,
                      value: key,
                      selected: key === _ctx.panelIndex
                    }, _toDisplayString(person.name) + " (" + _toDisplayString(person.email) + ") ", 9, _hoisted_7))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 8, _hoisted_6)
        ])
      ]))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}