import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled", "name"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      type: "file",
      disabled: _ctx.disabled,
      name: _ctx.name,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageUpload && _ctx.handleImageUpload(...args)))
    }, null, 40, _hoisted_1),
    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
    (_ctx.imageUrl || _ctx.currentImageUrl)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("img", {
            src: _ctx.imageUrl || _ctx.currentImageUrl,
            class: "img-fluid image-upload-preview"
          }, null, 8, _hoisted_2)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}