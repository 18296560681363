import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col col-12 mb-3" }
const _hoisted_3 = { class: "col col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentUploadField = _resolveComponent("DocumentUploadField")!
  const _component_DocumentMultiUploadField = _resolveComponent("DocumentMultiUploadField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("label", {
        for: "critical_reflection",
        class: "form-label"
      }, "Definitieve Kritische Reflectie", -1)),
      _createVNode(_component_DocumentUploadField, {
        id: "critical_reflection",
        name: "critical_reflection",
        "current-document": _ctx.data.critical_reflection?.document,
        disabled: _ctx.disabled
      }, null, 8, ["current-document", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("label", {
        for: "critical_reflection_attachments",
        class: "form-label"
      }, "Kritische Reflectie bijlages:", -1)),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _cache[3] || (_cache[3] = _createElementVNode("small", null, "Max. 3", -1)),
      _createVNode(_component_DocumentMultiUploadField, {
        id: "critical_reflection_attachments",
        name: "critical_reflection_attachments[]",
        "current-documents": _ctx.data.critical_reflection?.attachments,
        disabled: _ctx.disabled
      }, null, 8, ["current-documents", "disabled"])
    ])
  ]))
}