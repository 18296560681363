
import {computed, defineComponent, ref} from 'vue';
import useModal from '../../../composeables/useModal';
import usePartnershipInvites, {InviteSubmitResponse, PartnershipSelectionType} from '../../../composeables/usePartnershipInvites';
import PartnershipCard from '../../PartnershipCard.vue';
import ShortlistPeerReviewVisitorSelect from './ShortlistPeerReviewVisitorSelect.vue';
import useActiveTask from '../../../composeables/useActiveTask';
import TaskForm from '../../../form/TaskForm.vue';
import LimitedTextArea from '../../../form/LimitedTextArea.vue';
import ProgressSpinner from '@/components/standalone/ProgressSpinner.vue';
import SimpleForm from '@/form/SimpleForm.vue';

export default defineComponent({
    name: 'SelectVisitingPartnershipsTask',
    components: {SimpleForm, ProgressSpinner, LimitedTextArea, TaskForm, ShortlistPeerReviewVisitorSelect, PartnershipCard},
    setup() {
        usePartnershipInvites().refresh(useActiveTask().reviewId.value);
        useActiveTask().refreshActiveTaskProgress();

        const isSubmitting = ref(false);
        const selectedFreePartnership = computed(() => usePartnershipInvites().invitedPartnerships.value[PartnershipSelectionType.FREE]);
        const selectedShortlistPartnership = computed(() => usePartnershipInvites().invitedPartnerships.value[PartnershipSelectionType.SHORTLIST]);

        return {
            selectedFreePartnership,
            selectedShortlistPartnership,
            openModal() {
                useModal().open('FreePeerReviewVisitorSelect');
            },
            confirmChoices(form: HTMLFormElement) {
                const OK = ['pending', 'accepted'];
                if (!selectedFreePartnership.value || !selectedShortlistPartnership.value || !OK.includes(selectedFreePartnership.value.status) || !OK.includes(selectedShortlistPartnership.value.status)) {
                    window.alert('U moet beide uitnodigingen versturen om deze taak af te ronden.');
                    return;
                }

                isSubmitting.value = true;
                useActiveTask().submitTask(new FormData(form)).then((isSuccess) => {
                    if (isSuccess) {
                        usePartnershipInvites().refresh(useActiveTask().reviewId.value);
                    }
                }).finally(() => {
                    isSubmitting.value = false;
                });
            },
            onInviteSubmit(result: InviteSubmitResponse) {
                usePartnershipInvites().handleResponseData(result);
                useActiveTask().refreshActiveTaskProgress();
            },
            clearChoice: usePartnershipInvites().deselectPartnership,
            isLoading: usePartnershipInvites().isLoading,
            task: useActiveTask().activeTask,
            isSubmitting,
            PartnershipSelectionType
        }
    },
})
