import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col col-12" }
const _hoisted_3 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("label", null, "Datepicker URL:", -1)),
      _createElementVNode("input", {
        type: "url",
        name: "datepicker_url",
        class: "form-control",
        value: _ctx.data.datepicker_url,
        disabled: _ctx.disabled
      }, null, 8, _hoisted_3)
    ])
  ]))
}