import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["id", "onUpdate:modelValue", "value", "checked", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.isReady && _ctx.user)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("div", null, [
          _createElementVNode("b", null, "Contactpersoon toevoegen of wijzigen"),
          _createElementVNode("p", null, "Staat de beoogde coördinator van de peer review nog niet in de onderstaande lijst? Pas dan eerst de contactpersonen aan via de knop ‘Bewerken gegevens partnerschap’ rechtsboven op je dashboard. Klik vervolgens opnieuw op ‘Wijzig coördinatoren’ om de juiste personen te selecteren. Let op: het kan enkele minuten duren voordat de wijziging zichtbaar is.")
        ], -1)),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactPersons, (person, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "form-check"
            }, [
              _withDirectives(_createElementVNode("input", {
                id: `visiting_coordinator_${person.index}`,
                "onUpdate:modelValue": ($event: any) => ((_ctx.selectedChoices[key]) = $event),
                name: "receiving_coordinators[]",
                value: key,
                type: "checkbox",
                class: "form-check-input",
                checked: _ctx.selectedEmails.includes(person.email.toLowerCase()),
                disabled: _ctx.disabled,
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onUpdate && _ctx.onUpdate(...args)))
              }, null, 40, _hoisted_2), [
                [_vModelCheckbox, _ctx.selectedChoices[key]]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: `visiting_coordinator_${person.index}`
              }, _toDisplayString(person.name) + " | " + _toDisplayString(person.email), 9, _hoisted_3)
            ]))
          }), 128))
        ]),
        (_ctx.selectedEmails.length && !_ctx.selectedEmails.includes(_ctx.user.email.toLowerCase()))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
              _createTextVNode(" U heeft niet uzelf als coordinator gekozen."),
              _createElementVNode("br", null, null, -1),
              _createTextVNode(" Wanneer u deze taak voltooid "),
              _createElementVNode("b", null, "verliest u toegang", -1),
              _createTextVNode(" tot de peer review, inclusief deze taak. ")
            ])))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}