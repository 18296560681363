import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-content" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditReviewDataButton = _resolveComponent("EditReviewDataButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("b", null, _toDisplayString(_ctx.title), 1),
    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
    (_ctx.document)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: _ctx.document.url,
          target: "_blank"
        }, [
          _createTextVNode(" Download " + _toDisplayString(_ctx.title) + ": ", 1),
          _createElementVNode("b", null, _toDisplayString(_ctx.document.name), 1)
        ], 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createElementVNode("i", null, "Nog geen " + _toDisplayString(_ctx.title) + " geupload", 1)
        ])),
    _cache[1] || (_cache[1] = _createTextVNode("  ")),
    _createVNode(_component_EditReviewDataButton, { "data-key": _ctx.dataKey }, null, 8, ["data-key"])
  ]))
}