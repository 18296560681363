import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-3 edit-profile-page" }
const _hoisted_2 = ["value", "disabled"]
const _hoisted_3 = ["value", "disabled"]
const _hoisted_4 = ["value", "disabled"]
const _hoisted_5 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageUploadField = _resolveComponent("ImageUploadField")!
  const _component_SimpleForm = _resolveComponent("SimpleForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("h1", null, "Profiel Aanpassen", -1)),
    _cache[14] || (_cache[14] = _createElementVNode("div", null, null, -1)),
    (_ctx.user)
      ? (_openBlock(), _createBlock(_component_SimpleForm, {
          key: 0,
          target: "wp-json/api/set-user-info",
          class: "form-group",
          onResponse: _ctx.onResponse,
          onSubmit: _ctx.onSubmit
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", null, [
              _cache[0] || (_cache[0] = _createTextVNode(" Email: ")),
              _createElementVNode("input", {
                name: "email",
                type: "email",
                value: _ctx.user.email,
                class: "form-control",
                disabled: _ctx.disabled
              }, null, 8, _hoisted_2),
              _cache[1] || (_cache[1] = _createElementVNode("small", null, "Let op: Na het veranderen van uw e-mail zult u opnieuw in moeten loggen.", -1))
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("label", null, [
              _cache[2] || (_cache[2] = _createTextVNode(" Naam: ")),
              _createElementVNode("input", {
                name: "name",
                type: "text",
                value: _ctx.user.name,
                class: "form-control",
                disabled: _ctx.disabled
              }, null, 8, _hoisted_3)
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("label", null, [
              _cache[3] || (_cache[3] = _createTextVNode(" Functie: ")),
              _createElementVNode("input", {
                name: "function",
                type: "text",
                value: _ctx.user.duty,
                class: "form-control",
                disabled: _ctx.disabled
              }, null, 8, _hoisted_4)
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("label", null, [
              _cache[4] || (_cache[4] = _createTextVNode(" LinkedIn URL: ")),
              _createElementVNode("input", {
                name: "linkedIn",
                type: "url",
                value: _ctx.user.linkedInUrl,
                class: "form-control",
                disabled: _ctx.disabled
              }, null, 8, _hoisted_5)
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("label", null, [
              _cache[5] || (_cache[5] = _createTextVNode(" Foto:")),
              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_ImageUploadField, {
                name: "photo",
                "current-image-url": _ctx.user.avatarUrl,
                disabled: _ctx.disabled
              }, null, 8, ["current-image-url", "disabled"])
            ]),
            _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
            _cache[12] || (_cache[12] = _createElementVNode("button", { class: "btn btn-primary" }, "Opslaan", -1))
          ]),
          _: 1
        }, 8, ["onResponse", "onSubmit"]))
      : _createCommentVNode("", true)
  ]))
}