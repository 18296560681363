import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pdf-export p-4 pr-6" }
const _hoisted_2 = { class: "py-2 px-3 bg--darker-transparent text-center" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipIdentity = _resolveComponent("PartnershipIdentity")!
  const _component_Vue3Html2pdf = _resolveComponent("Vue3Html2pdf")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "btn btn-primary",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)))
    }, "Download partnerschap ID"),
    _createVNode(_component_Vue3Html2pdf, {
      ref: "html2Pdf",
      "preview-modal": false,
      "show-layout": false,
      "paginate-elements-by-height": 30000,
      "pdf-quality": 2,
      "manual-pagination": false,
      "pdf-format": "a4",
      "pdf-orientation": "portrait",
      "pdf-content-width": "800px",
      "html-to-pdf-options": {
            enableLinks: true,
            filename: `ID ${_ctx.partnership.label} ${Date.now()}`,
            margin: 10
        }
    }, {
      "pdf-content": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(), _createBlock(_component_PartnershipIdentity, {
            key: Date.now(),
            partnership: _ctx.partnership
          }, null, 8, ["partnership"])),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("b", null, "Bekijk uitgebreide profielpagina op website Platform Samen Opleiden", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("b", null, [
              _createElementVNode("a", {
                href: _ctx.partnership.profilePage,
                target: "_blank"
              }, _toDisplayString(_ctx.partnership.profilePage), 9, _hoisted_3)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["html-to-pdf-options"])
  ], 64))
}