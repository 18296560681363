import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.partnership)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.inviteStatus === 'accepted')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createElementVNode("b", null, _toDisplayString(_ctx.partnership.label), 1),
              _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.partnership.address), 1),
              _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.partnership.zipCode) + " " + _toDisplayString(_ctx.partnership.city), 1),
              _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("a", {
                href: _ctx.partnership.profilePage,
                target: "_blank"
              }, "Bekijk profielpagina", 8, _hoisted_2)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createElementVNode("b", null, _toDisplayString(_ctx.partnership.label), 1),
              _cache[3] || (_cache[3] = _createTextVNode(" is uitgenodigd, maar heeft nog niet gereageerd."))
            ]))
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createTextVNode(" Partnerschap #" + _toDisplayString(_ctx.index) + " is nog niet gekozen. ", 1)
      ], 64))
}