import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "partnership-single__name py-2 px-3" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "partnership-single__details bg-image-white" }
const _hoisted_5 = { class: "py-2 px-3 border-bottom" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-6 col-md-3" }
const _hoisted_8 = { class: "definition" }
const _hoisted_9 = { class: "definition__value" }
const _hoisted_10 = { class: "col-6 col-md-3" }
const _hoisted_11 = { class: "definition" }
const _hoisted_12 = { class: "definition__value" }
const _hoisted_13 = { class: "col-6 col-md-3" }
const _hoisted_14 = { class: "definition" }
const _hoisted_15 = { class: "definition__value" }
const _hoisted_16 = { class: "col-6 col-md-3" }
const _hoisted_17 = { class: "definition" }
const _hoisted_18 = { class: "definition__value" }
const _hoisted_19 = { class: "py-2 px-3 border-bottom" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-6 col-md-4" }
const _hoisted_22 = { class: "definition" }
const _hoisted_23 = { class: "definition__value" }
const _hoisted_24 = { class: "col-6 col-md-4" }
const _hoisted_25 = { class: "definition" }
const _hoisted_26 = {
  key: 0,
  class: "definition__value"
}
const _hoisted_27 = {
  key: 1,
  class: "definition__value"
}
const _hoisted_28 = {
  key: 0,
  class: "py-2 px-3 border-bottom"
}
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-12 col-md-12" }
const _hoisted_31 = { class: "definition" }
const _hoisted_32 = {
  key: 0,
  class: "chart-application"
}
const _hoisted_33 = ["href"]
const _hoisted_34 = {
  key: 1,
  class: "chart-image"
}
const _hoisted_35 = ["src", "alt"]
const _hoisted_36 = { class: "py-2 px-3" }
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "definition" }
const _hoisted_39 = { class: "definition__label" }
const _hoisted_40 = { class: "definition__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.partnership.logo)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.partnership.logo,
            class: "partnership-single__name__logo",
            alt: `Logo ${_ctx.partnership.label}`,
            width: "100",
            height: "100"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.partnership.label), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.partnership.city), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "definition__label" }, "Sector", -1)),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.partnership.educationSector ? _ctx.partnership.educationSector : '-'), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "definition__label" }, "Jaar van oprichting", -1)),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.partnership.dateOfEstablishment ? _ctx.partnership.dateOfEstablishment : '-'), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "definition__label" }, "Jaar van erkenning", -1)),
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.partnership.yearOfRecognition ? _ctx.partnership.yearOfRecognition : '-'), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "definition__label" }, "Aantal studenten (2021/2022)", -1)),
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.partnership.totalStudents), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "definition__label" }, "Datum laatste beoordeling partnerschap door NVAO", -1)),
              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.partnership.nvaoDate || '-'), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "definition__label" }, "Alle bij het partnerschap aangesloten scholen vallen onder het basisarrangement van de Inspectie", -1)),
              (typeof _ctx.partnership.partnerQuality !== 'boolean')
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, "Ja"))
                : (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.partnership.partnerQuality ? 'Ja' : 'Nee'), 1))
            ])
          ])
        ])
      ]),
      (_ctx.partnership.organizationChartType && _ctx.partnership.organizationChartUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "definition__label" }, "Organogram", -1)),
                  (_ctx.partnership.organizationChartType === 'application')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                        _createElementVNode("a", {
                          href: _ctx.partnership.organizationChartUrl,
                          target: "_blank"
                        }, "Bekijk Organogram", 8, _hoisted_33)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_34, [
                        _createElementVNode("img", {
                          src: _ctx.imageBlob,
                          alt: `Organization chart of ${_ctx.partnership.label}`
                        }, null, 8, _hoisted_35)
                      ]))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_36, [
        _createElementVNode("div", _hoisted_37, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnership.partnerCount, (partner, partnerKey) => {
            return (_openBlock(), _createElementBlock("div", {
              key: partnerKey,
              class: "col-6 col-md-4"
            }, [
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, "Aantal " + _toDisplayString(partner.label), 1),
                _createElementVNode("div", _hoisted_40, _toDisplayString(partner.amount), 1)
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}