import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "custom-file"
}
const _hoisted_2 = ["name"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("input", {
            type: "file",
            class: "custom-file-input",
            name: _ctx.name,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDocumentUpload && _ctx.handleDocumentUpload(...args)))
          }, null, 40, _hoisted_2),
          _cache[1] || (_cache[1] = _createElementVNode("label", { class: "custom-file-label" }, "Kies bestand", -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDocument.url)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("a", {
            href: _ctx.activeDocument.url,
            target: "_blank"
          }, _toDisplayString(_ctx.activeDocument.name), 9, _hoisted_3),
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}