import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "partnership-single"
}
const _hoisted_2 = { class: "partnership-single__details bg-image-white" }
const _hoisted_3 = { class: "py-2 px-3 bg--darker-transparent text-center" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "py-3 px-3" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "py-2 px-3 bg--darker-transparent" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = ["href"]
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipIdentity = _resolveComponent("PartnershipIdentity")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.partnership)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PartnershipIdentity, { partnership: _ctx.partnership }, null, 8, ["partnership"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("b", null, [
              _createElementVNode("a", {
                href: _ctx.partnership.profilePage,
                target: "_blank"
              }, "» Bekijk uitgebreide profielpagina op website Platform Samen Opleiden", 8, _hoisted_4)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.partnership.notification)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "partnership-notification mb-2",
                innerHTML: _ctx.partnership.notification
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          (_ctx.partnership.aboutUs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnership.aboutUs, (item, itemKey) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: itemKey,
                    class: "partnership-single__question"
                  }, [
                    _createElementVNode("h6", null, _toDisplayString(item.question) + ":", 1),
                    (item.answer)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          innerHTML: item.answer
                        }, null, 8, _hoisted_8))
                      : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[0] || (_cache[0] = [
                          _createElementVNode("p", null, "-", -1)
                        ])))
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[4] || (_cache[4] = _createElementVNode("b", null, "Contactpersoon:", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
          (_ctx.partnership.contactName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createTextVNode(_toDisplayString(_ctx.partnership.contactName), 1),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.partnership.contactFunction)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("i", null, _toDisplayString(_ctx.partnership.contactFunction), 1),
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.partnership.contactEmail)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("a", {
                  href: `mailto:${_ctx.partnership.contactEmail}`
                }, _toDisplayString(_ctx.partnership.contactEmail), 9, _hoisted_14),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.partnership.contactPhone)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.partnership.contactPhone), 1))
            : _createCommentVNode("", true),
          (_ctx.partnership.available)
            ? (_openBlock(), _createElementBlock("span", _hoisted_16, "✔ Dit partnerschap is nog beschikbaar als bezoeker voor een Peer Review."))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}