import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col col-12 mb-3" }
const _hoisted_3 = { class: "col col-12 form-group" }
const _hoisted_4 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentUploadField = _resolveComponent("DocumentUploadField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("label", { for: "" }, "Concept Verslag:", -1)),
      _createVNode(_component_DocumentUploadField, {
        name: "review_report_concept",
        "current-document": _ctx.data.review_report_concept?.document,
        disabled: _ctx.disabled
      }, null, 8, ["current-document", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("label", { for: "concept_report_comment" }, "Toelichting (optioneel)", -1)),
      _createElementVNode("textarea", {
        id: "concept_report_comment",
        class: "form-control",
        name: "concept_report_comment",
        type: "text",
        value: _ctx.data.review_report_concept?.comment,
        disabled: _ctx.disabled
      }, null, 8, _hoisted_4)
    ])
  ]))
}