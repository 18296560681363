import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "message-item__content" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "message-item__recipient" }
const _hoisted_4 = { class: "message-item__date" }
const _hoisted_5 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeerAvatar = _resolveComponent("PeerAvatar")!
  const _component_BaseBadge = _resolveComponent("BaseBadge")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    to: {name: 'InboxSingle', params: {reviewId: _ctx.thread.reviewId, id: _ctx.thread.id}},
    class: _normalizeClass(["message-item message-item--inbox active", {'item-unread': _ctx.thread.unreadMessages}])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PeerAvatar, {
        user: _ctx.thread.sender || undefined,
        "show-name": false,
        "show-button": false
      }, null, 8, ["user"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.thread.subject) + " ", 1),
          (_ctx.thread.task && !_ctx.thread.task.isComplete)
            ? (_openBlock(), _createBlock(_component_BaseBadge, {
                key: 0,
                value: 1,
                type: "success"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, "Betreft: " + _toDisplayString(_ctx.thread.partnershipShortName || _ctx.thread.partnershipName), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("time", {
            datetime: _ctx.thread.createdAt
          }, _toDisplayString(_ctx.thread.createdAt), 9, _hoisted_5)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row no-gutters" }, null, -1))
      ])
    ]),
    _: 1
  }, 8, ["to", "class"]))
}