import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "custom-file"
}
const _hoisted_2 = ["name"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("input", {
            id: "document_multi_upload",
            type: "file",
            class: "custom-file-input",
            name: _ctx.name,
            multiple: "multiple",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDocumentUpload && _ctx.handleDocumentUpload(...args)))
          }, null, 40, _hoisted_2),
          _cache[1] || (_cache[1] = _createElementVNode("label", {
            class: "custom-file-label",
            for: "document_multi_upload"
          }, "Kies bestanden", -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDocuments?.length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _cache[3] || (_cache[3] = _createElementVNode("b", null, "Huidige bestanden:", -1)),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeDocuments, (document, dkey) => {
              return (_openBlock(), _createElementBlock("li", { key: dkey }, [
                _createElementVNode("a", {
                  href: document.url,
                  target: "_blank"
                }, _toDisplayString(document.name), 9, _hoisted_3)
              ]))
            }), 128))
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}