import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomHeading = _resolveComponent("CustomHeading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CustomHeading, {
      title: "Stappenplan & contact",
      class: "custom-heading-steps-contact"
    }),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "main__inner px-1 px-lg-5" }, [
      _createElementVNode("p", null, "Doel van de ontwikkelingsgerichte peer review is kwaliteitsontwikkeling. Erkende partnerschappen Samen Opleiden voeren elke zes jaar een ontwikkelingsgerichte peer review uit aan de hand van de waarborgen uit het Kwaliteitskader Samen Opleiden & Inductie. In dit stappenplan geven we een overzicht van het peer review proces in de tijd uitgezet en een beknopte toelichting op de indeling en het gebruik van de Peer Review Planner."),
      _createElementVNode("p", null, [
        _createElementVNode("a", {
          href: "https://www.platformsamenopleiden.nl/wp-content/uploads/2023/01/230125-Stappenplan-Peer-Review-Planner.pdf",
          target: "_blank",
          class: "btn btn-primary"
        }, "Naar stappenplan peer review")
      ]),
      _createElementVNode("h3", null, "Vragen?"),
      _createElementVNode("p", null, [
        _createTextVNode("Voor meer informatie over de ontwikkelingsgerichte peer review, het peer review proces en/of de Peer Review Planner, neem contact met ons op via "),
        _createElementVNode("a", { href: "mailto:info@peerreviewplanner.nl" }, "info@peerreviewplanner.nl"),
        _createTextVNode(".")
      ])
    ], -1))
  ], 64))
}