import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    class: "data-form p-3",
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _cache[1] || (_cache[1] = _createElementVNode("button", { class: "btn btn-primary mt-2" }, "Opslaan", -1))
  ], 544))
}