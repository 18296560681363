import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col col-12" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "row mb-3" }
const _hoisted_5 = { class: "col col-12 col-md-6" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "col col-12 col-md-6" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "row mb-3" }
const _hoisted_10 = { class: "col col-12" }
const _hoisted_11 = { class: "form-check form-check-inline" }
const _hoisted_12 = ["disabled", "checked"]
const _hoisted_13 = { class: "form-check form-check-inline" }
const _hoisted_14 = ["disabled", "checked"]
const _hoisted_15 = {
  key: 0,
  class: "row mb-3"
}
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 1,
  class: "row mb-3"
}
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[7] || (_cache[7] = _createElementVNode("label", null, "Overleg datum:", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.discussionDate) = $event)),
          type: "date",
          name: "preliminary_discussion_date",
          disabled: _ctx.disabled,
          class: "form-control",
          required: ""
        }, null, 8, _hoisted_3), [
          [_vModelText, _ctx.discussionDate]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[8] || (_cache[8] = _createElementVNode("label", null, "Overleg begintijd:", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.discussionStartTime) = $event)),
          type: "time",
          name: "preliminary_discussion_start_time",
          class: "form-control",
          disabled: _ctx.disabled,
          required: ""
        }, null, 8, _hoisted_6), [
          [_vModelText, _ctx.discussionStartTime]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[9] || (_cache[9] = _createElementVNode("label", null, [
          _createTextVNode("Overleg eindtijd: "),
          _createElementVNode("small", null, "(Optioneel)")
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.discussionEndTime) = $event)),
          type: "time",
          name: "preliminary_discussion_end_time",
          class: "form-control",
          disabled: _ctx.disabled
        }, null, 8, _hoisted_8), [
          [_vModelText, _ctx.discussionEndTime]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[12] || (_cache[12] = _createElementVNode("label", null, "Waar:", -1)),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_11, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.locationType) = $event)),
              type: "radio",
              class: "form-check-input",
              name: "preliminary_discussion_location_type",
              value: "location",
              disabled: _ctx.disabled,
              checked: _ctx.data.preliminary_discussion.locationType === 'location',
              required: ""
            }, null, 8, _hoisted_12), [
              [_vModelRadio, _ctx.locationType]
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-check-label" }, "Op Locatie", -1))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.locationType) = $event)),
              type: "radio",
              class: "form-check-input",
              name: "preliminary_discussion_location_type",
              value: "online",
              disabled: _ctx.disabled,
              checked: _ctx.data.preliminary_discussion.locationType === 'online',
              required: ""
            }, null, 8, _hoisted_14), [
              [_vModelRadio, _ctx.locationType]
            ]),
            _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-check-label" }, "Online", -1))
          ])
        ])
      ])
    ]),
    (_ctx.locationType === 'location')
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _cache[13] || (_cache[13] = _createElementVNode("label", null, "Locatie:", -1)),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.locationDetails) = $event)),
            type: "text",
            name: "preliminary_discussion_location_details",
            class: "form-control",
            disabled: _ctx.disabled,
            required: ""
          }, null, 8, _hoisted_16), [
            [_vModelText, _ctx.locationDetails]
          ])
        ]))
      : (_ctx.locationType === 'online')
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _cache[14] || (_cache[14] = _createElementVNode("label", null, "Meeting URL:", -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.locationDetails) = $event)),
              type: "url",
              name: "preliminary_discussion_location_details",
              class: "form-control",
              disabled: _ctx.disabled
            }, null, 8, _hoisted_18), [
              [_vModelText, _ctx.locationDetails]
            ])
          ]))
        : _createCommentVNode("", true)
  ]))
}