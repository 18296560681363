import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "organization" }
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseBadge = _resolveComponent("BaseBadge")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    to: {name: 'TasksSingle', params: {reviewId: _ctx.task.reviewId, id: _ctx.task.id}},
    class: _normalizeClass(["message-item message-item--task", {'task-complete': _ctx.task.isComplete}])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.task.partnershipShortName || _ctx.task.partnershipName), 1),
      _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.task.title), 1),
      _createElementVNode("time", {
        class: "mt-auto",
        datetime: _ctx.task.createdAt
      }, _toDisplayString(_ctx.task.createdAt), 9, _hoisted_3),
      (_ctx.task.isComplete)
        ? (_openBlock(), _createBlock(_component_BaseBadge, {
            key: 0,
            type: "success",
            class: ""
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("✓")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}