import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-3 review-day-times" }
const _hoisted_2 = { class: "col col-12 col-md-6" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "col col-12 col-md-6" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "row mb-3" }
const _hoisted_7 = { class: "col col-12" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentMultiUploadField = _resolveComponent("DocumentMultiUploadField")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("label", { for: "review_day_start_time" }, "Peer review dag begintijd:", -1)),
        _withDirectives(_createElementVNode("input", {
          id: "review_day_start_time",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reviewDayStartTime) = $event)),
          type: "time",
          name: "review_day_start_time",
          disabled: _ctx.disabled,
          class: "form-control",
          required: "",
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onUpdate && _ctx.onUpdate(...args)))
        }, null, 40, _hoisted_3), [
          [_vModelText, _ctx.reviewDayStartTime]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[7] || (_cache[7] = _createElementVNode("label", { for: "review_day_end_time" }, [
          _createTextVNode("Peer review dag eindtijd: "),
          _createElementVNode("small", null, "(Optioneel)")
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          id: "review_day_end_time",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reviewDayEndTime) = $event)),
          type: "time",
          name: "review_day_end_time",
          disabled: _ctx.disabled,
          class: "form-control",
          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onUpdate && _ctx.onUpdate(...args)))
        }, null, 40, _hoisted_5), [
          [_vModelText, _ctx.reviewDayEndTime]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _cache[8] || (_cache[8] = _createElementVNode("label", { for: "review_day_location_details" }, "Locatie:", -1)),
        _withDirectives(_createElementVNode("textarea", {
          id: "review_day_location_details",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reviewDayLocation) = $event)),
          type: "text",
          name: "review_day_location_details",
          class: "form-control",
          disabled: _ctx.disabled,
          required: "",
          onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onUpdate && _ctx.onUpdate(...args)))
        }, null, 40, _hoisted_8), [
          [_vModelText, _ctx.reviewDayLocation]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[9] || (_cache[9] = _createElementVNode("label", null, "Planning peerreview dag en andere documenten:", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_DocumentMultiUploadField, {
            name: "review_day_documents[]",
            "current-documents": _ctx.data.peer_review_day_program?.documents,
            disabled: _ctx.disabled,
            onChange: _ctx.onUpdate
          }, null, 8, ["current-documents", "disabled", "onChange"])
        ])
      ])
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1))
  ]))
}