import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-mask"
}
const _hoisted_2 = {
  class: "modal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "modal-dialog modal-dialog-centered modal-lg" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-header" }
const _hoisted_6 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.modalComponent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.canGoBack)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "modal__button-goback float-right",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
                        }, "←"))
                      : _createCommentVNode("", true),
                    _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "modal-title" }, null, -1)),
                    _createElementVNode("button", {
                      class: "modal__button-close float-right",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                    })
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalStack, (mcName, mcKey) => {
                      return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(mcName), { key: mcKey })), [
                        [_vShow, (_ctx.modalStack.length <= mcKey + 1)]
                      ])
                    }), 128))
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}