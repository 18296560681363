import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  open: ""
}
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = {
  key: 2,
  class: "font-weight-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InviteField = _resolveComponent("InviteField")!
  const _component_TaskForm = _resolveComponent("TaskForm")!

  return (_openBlock(), _createBlock(_component_TaskForm, { onSubmit: _ctx.onSubmit }, {
    default: _withCtx(() => [
      (_ctx.invites?.mainInvite)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_InviteField, {
              invite: _ctx.invites.mainInvite,
              disabled: _ctx.task?.isComplete || _ctx.invites.mainInvite.status !== 'pending',
              onChange: _ctx.onUpdate
            }, null, 8, ["invite", "disabled", "onChange"])
          ]))
        : _createCommentVNode("", true),
      _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
      (_ctx.invites?.otherInvites.length)
        ? (_openBlock(), _createElementBlock("details", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("summary", { class: "font-weight-bold" }, "U heeft ook nog de volgende uitnodigingen gekregen:", -1)),
            _createElementVNode("table", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Partnerschap")
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invites.otherInvites, (invite, key) => {
                return (_openBlock(), _createElementBlock("tr", { key: key }, [
                  _createElementVNode("td", null, _toDisplayString(invite.sendingPartnership.label), 1)
                ]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, "U heeft verder geen openstaande uitnodigingen."))
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}