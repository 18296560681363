import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "review-single__name px-4 py-1" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "review-single__details bg-image-white" }
const _hoisted_4 = { class: "review-single__details__school p-4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 col-md-4" }
const _hoisted_7 = { class: "col-12 col-md-4" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "col-12 col-md-4 d-flex justify-content-end" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "review-single__details__date bg--darker-transparent px-4 py-3" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "review-single__body p-4" }
const _hoisted_17 = { class: "card-content" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-12 col-md-6" }
const _hoisted_20 = { class: "col-12 col-md-6" }
const _hoisted_21 = {
  key: 0,
  class: "card-content--darker"
}
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-12 col-md-6" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { key: 3 }
const _hoisted_27 = { class: "col-12 col-md-6" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { key: 3 }
const _hoisted_31 = {
  key: 1,
  class: "card-content"
}
const _hoisted_32 = {
  key: 0,
  class: "row"
}
const _hoisted_33 = { class: "col-12 col-md-6" }
const _hoisted_34 = { class: "col-12 col-md-6" }
const _hoisted_35 = {
  key: 1,
  class: "row"
}
const _hoisted_36 = { class: "card-content" }
const _hoisted_37 = { class: "card-content--darker" }
const _hoisted_38 = ["href"]
const _hoisted_39 = { class: "card-content" }
const _hoisted_40 = { class: "row mb-3" }
const _hoisted_41 = { class: "col-12 col-md-6" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = { key: 1 }
const _hoisted_44 = { class: "col-12 col-md-6" }
const _hoisted_45 = { key: 0 }
const _hoisted_46 = { key: 1 }
const _hoisted_47 = { key: 2 }
const _hoisted_48 = { key: 0 }
const _hoisted_49 = { key: 1 }
const _hoisted_50 = { class: "card-content" }
const _hoisted_51 = { class: "row mb-3" }
const _hoisted_52 = { class: "col-12 col-md-6" }
const _hoisted_53 = { key: 0 }
const _hoisted_54 = { key: 1 }
const _hoisted_55 = { class: "col-12 col-md-6" }
const _hoisted_56 = { key: 0 }
const _hoisted_57 = { key: 1 }
const _hoisted_58 = { class: "row mb-3" }
const _hoisted_59 = { class: "col-12 col-md-6" }
const _hoisted_60 = { key: 0 }
const _hoisted_61 = { key: 1 }
const _hoisted_62 = {
  key: 0,
  class: "col-12 col-md-6"
}
const _hoisted_63 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditReviewDataButton = _resolveComponent("EditReviewDataButton")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_VisitingPartnership = _resolveComponent("VisitingPartnership")!
  const _component_CoordinatorList = _resolveComponent("CoordinatorList")!
  const _component_PanelMemberUserInfo = _resolveComponent("PanelMemberUserInfo")!
  const _component_BsCard = _resolveComponent("BsCard")!
  const _component_PartnershipIdentityDownload = _resolveComponent("PartnershipIdentityDownload")!
  const _component_BsDocumentCard = _resolveComponent("BsDocumentCard")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.user && _ctx.reviewData?.receiving_partnership)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["review-single", `partnership-${_ctx.reviewData.receiving_partnership.id}`])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.reviewData?.receiving_partnership?.logo)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.reviewData?.receiving_partnership.logo,
                class: "review-single__name__logo",
                width: "100",
                height: "100",
                alt: ""
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("h1", null, _toDisplayString(_ctx.reviewData?.receiving_partnership.label), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("b", null, _toDisplayString(_ctx.reviewData?.receiving_partnership.label), 1),
                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.reviewData?.receiving_partnership.address), 1),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.reviewData?.receiving_partnership.zipCode) + " " + _toDisplayString(_ctx.reviewData?.receiving_partnership.city), 1),
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.reviewData.receiving_coordinators.map(o => o.id).includes(_ctx.user.id))
                  ? (_openBlock(), _createBlock(_component_EditReviewDataButton, {
                      key: 0,
                      "data-key": "receiving_coordinators",
                      class: "float-right"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Wijzig coördinatoren ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviewData?.receiving_coordinators, (coordinator, key) => {
                  return (_openBlock(), _createElementBlock("div", { key: key }, [
                    _createVNode(_component_UserAvatar, { user: coordinator }, null, 8, ["user"]),
                    _createElementVNode("b", null, _toDisplayString(coordinator.name), 1),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(coordinator.duty), 1),
                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                    (coordinator.phoneNumber)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                          _createTextVNode(_toDisplayString(coordinator.phoneNumber), 1),
                          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(coordinator.email), 1),
                      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
                    ])
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.reviewData?.receiving_partnership.inquiryUrl)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("a", {
                        href: _ctx.reviewData?.receiving_partnership.inquiryUrl
                      }, "» Bewerk gegevens partnerschap", 8, _hoisted_11),
                      _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_ctx.reviewData?.start_date)
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                  _cache[10] || (_cache[10] = _createTextVNode(" Peer reviewdag gepland op: ")),
                  _createElementVNode("b", null, _toDisplayString(_ctx.reviewData?.start_date), 1),
                  _cache[11] || (_cache[11] = _createTextVNode("   ")),
                  _createVNode(_component_EditReviewDataButton, { "data-key": "start_date" }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Wijzig startdatum")
                    ])),
                    _: 1
                  })
                ]))
              : (_ctx.reviewData?.timeslot)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                    _cache[13] || (_cache[13] = _createTextVNode(" Peer reviewdag is nog niet gepland, maar valt in tijdsblok: ")),
                    _createElementVNode("b", null, _toDisplayString(_ctx.reviewData?.timeslot.startDate) + " - " + _toDisplayString(_ctx.reviewData?.timeslot.endDate), 1),
                    _cache[14] || (_cache[14] = _createTextVNode("   ")),
                    _createVNode(_component_EditReviewDataButton, { "data-key": "timeslot" }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Wijzig tijdsblok")
                      ])),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_15, "Peer reviewdag en tijdblok zijn nog niet gepland"))
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_BsCard, { title: "Bezoekend panel" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[15] || (_cache[15] = _createElementVNode("h5", null, "Bezoekend partnerschap 1", -1)),
                    _createVNode(_component_VisitingPartnership, {
                      "invite-status": _ctx.reviewData.visiting_partnerships.firstStatus ?? undefined,
                      partnership: _ctx.reviewData.visiting_partnerships.first ?? undefined,
                      index: 1
                    }, null, 8, ["invite-status", "partnership"])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[16] || (_cache[16] = _createElementVNode("h5", null, "Bezoekend partnerschap 2", -1)),
                    _createVNode(_component_VisitingPartnership, {
                      "invite-status": _ctx.reviewData.visiting_partnerships.secondStatus ?? undefined,
                      partnership: _ctx.reviewData.visiting_partnerships.second ?? undefined,
                      index: 2
                    }, null, 8, ["invite-status", "partnership"])
                  ])
                ])
              ]),
              ((_ctx.showFirstVisitors || _ctx.showSecondVisitors))
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        (_ctx.showFirstVisitors)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_ctx.reviewData.visiting_coordinators_1.length === 1)
                                ? (_openBlock(), _createElementBlock("h6", _hoisted_24, "Coordinator / Contactpersoon Panel"))
                                : (_openBlock(), _createElementBlock("h6", _hoisted_25, "Coordinatoren / Contactpersonen Panel")),
                              (_ctx.reviewData?.visiting_coordinators_1?.length)
                                ? (_openBlock(), _createBlock(_component_CoordinatorList, {
                                    key: 2,
                                    coordinators: _ctx.reviewData.visiting_coordinators_1
                                  }, null, 8, ["coordinators"]))
                                : (_openBlock(), _createElementBlock("i", _hoisted_26, "Er zijn nog geen coordinatoren toegewezen aan het panel van dit partnerschap.")),
                              (_ctx.reviewData.visiting_coordinators_1.map(c => c.id).includes(_ctx.user.id))
                                ? (_openBlock(), _createBlock(_component_EditReviewDataButton, {
                                    key: 4,
                                    "data-key": "visiting_coordinators_1"
                                  }, {
                                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                                      _createTextVNode(" Wijzig bezoekende coördinatoren ")
                                    ])),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        (_ctx.showSecondVisitors)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_ctx.reviewData.visiting_coordinators_2.length === 1)
                                ? (_openBlock(), _createElementBlock("h6", _hoisted_28, "Coordinator"))
                                : (_openBlock(), _createElementBlock("h6", _hoisted_29, "Coordinatoren")),
                              (_ctx.reviewData?.visiting_coordinators_2?.length)
                                ? (_openBlock(), _createBlock(_component_CoordinatorList, {
                                    key: 2,
                                    coordinators: _ctx.reviewData.visiting_coordinators_2
                                  }, null, 8, ["coordinators"]))
                                : (_openBlock(), _createElementBlock("i", _hoisted_30, "Er zijn nog geen coordinatoren toegewezen aan het panel van dit partnerschap.")),
                              (_ctx.reviewData.visiting_coordinators_2.map(c => c.id).includes(_ctx.user.id))
                                ? (_openBlock(), _createBlock(_component_EditReviewDataButton, {
                                    key: 4,
                                    "data-key": "visiting_coordinators_2",
                                    class: "w-100"
                                  }, {
                                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                                      _createTextVNode(" Wijzig bezoekende coördinatoren ")
                                    ])),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              ((_ctx.showFirstVisitors || _ctx.showSecondVisitors) && _ctx.user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createVNode(_component_EditReviewDataButton, {
                      "data-key": "panel_member_roles",
                      class: "w-100"
                    }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode("Wijzig verdeling van panel rollen")
                      ])),
                      _: 1
                    }),
                    (_ctx.panelRoles.first.length && _ctx.panelRoles.second.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("h6", null, "Panel " + _toDisplayString(_ctx.reviewData?.visiting_partnerships.first?.label), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panelRoles.first, (role, key) => {
                              return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                                (_ctx.panelUsers[role])
                                  ? (_openBlock(), _createBlock(_component_PanelMemberUserInfo, {
                                      key: 0,
                                      "panel-member": _ctx.panelUsers[role]
                                    }, null, 8, ["panel-member"]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128)),
                            (_ctx.reviewData?.visiting_coordinators_1.map(o => o.id).includes(_ctx.user.id))
                              ? (_openBlock(), _createBlock(_component_EditReviewDataButton, {
                                  key: 0,
                                  "data-key": "panel_members_1",
                                  class: "w-100"
                                }, {
                                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                                    _createTextVNode(" Wijzig panelleden ")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_34, [
                            _createElementVNode("h6", null, "Panel " + _toDisplayString(_ctx.reviewData?.visiting_partnerships.second?.label), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panelRoles.second, (role, key) => {
                              return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                                (_ctx.panelUsers[role])
                                  ? (_openBlock(), _createBlock(_component_PanelMemberUserInfo, {
                                      key: 0,
                                      "panel-member": _ctx.panelUsers[role]
                                    }, null, 8, ["panel-member"]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128)),
                            (_ctx.reviewData?.visiting_coordinators_2.map(o => o.id).includes(_ctx.user.id))
                              ? (_openBlock(), _createBlock(_component_EditReviewDataButton, {
                                  key: 0,
                                  "data-key": "panel_members_2",
                                  class: "w-100"
                                }, {
                                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                                    _createTextVNode(" Wijzig panelleden ")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[22] || (_cache[22] = [
                          _createElementVNode("i", { class: "col-12 col-md-12 text-center" }, " Er zijn nog geen panel rollen toegewezen aan de partnerschappen. ", -1)
                        ])))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_BsCard, { title: "Partnerschap ID" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  (_ctx.reviewData?.receiving_partnership)
                    ? (_openBlock(), _createBlock(_component_PartnershipIdentityDownload, {
                        key: 0,
                        partnership: _ctx.reviewData.receiving_partnership
                      }, null, 8, ["partnership"]))
                    : _createCommentVNode("", true),
                  (_ctx.reviewData?.receiving_partnership.inquiryUrl)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        class: "btn btn-primary ml-3",
                        href: _ctx.reviewData.receiving_partnership.inquiryUrl
                      }, " Wijzig gegevens ID ", 8, _hoisted_38))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_BsCard, { title: "Kritische reflectie" }, {
            default: _withCtx(() => [
              _createVNode(_component_BsDocumentCard, {
                title: "Kritische reflectie",
                document: _ctx.reviewData?.critical_reflection?.document,
                "data-key": "critical_reflection"
              }, null, 8, ["document"])
            ]),
            _: 1
          }),
          _createVNode(_component_BsCard, { title: "Voorbespreking bezoekend panel" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, [
                    _cache[23] || (_cache[23] = _createElementVNode("b", null, "Datum voorbespreking", -1)),
                    _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
                    (_ctx.reviewData.preliminary_discussion.date)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_42, _toDisplayString(_ctx.reviewData.preliminary_discussion.date), 1))
                      : (_openBlock(), _createElementBlock("i", _hoisted_43, "Datum is nog niet gepland"))
                  ]),
                  _createElementVNode("div", _hoisted_44, [
                    _cache[25] || (_cache[25] = _createElementVNode("b", null, "Aanvangstijd", -1)),
                    _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1)),
                    (_ctx.reviewData.preliminary_discussion.startTime && _ctx.reviewData.preliminary_discussion.endTime)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString(_ctx.reviewData.preliminary_discussion.startTime) + " - " + _toDisplayString(_ctx.reviewData.preliminary_discussion.endTime), 1))
                      : (_ctx.reviewData.preliminary_discussion.startTime)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_46, _toDisplayString(_ctx.reviewData.preliminary_discussion.startTime), 1))
                        : (_openBlock(), _createElementBlock("i", _hoisted_47, "Tijd is nog niet gepland"))
                  ])
                ]),
                _cache[27] || (_cache[27] = _createElementVNode("b", null, "Locatie", -1)),
                _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
                (_ctx.reviewData.preliminary_discussion.locationDetails)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_48, _toDisplayString(_ctx.reviewData.preliminary_discussion.locationDetails), 1))
                  : (_openBlock(), _createElementBlock("i", _hoisted_49, "Locatie is nog niet vastgesteld"))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_BsCard, { title: "Peer reviewdag" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_50, [
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", _hoisted_52, [
                    _cache[29] || (_cache[29] = _createElementVNode("b", null, "Datum peer reviewdag", -1)),
                    _cache[30] || (_cache[30] = _createElementVNode("br", null, null, -1)),
                    (_ctx.reviewData?.start_date)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_53, _toDisplayString(_ctx.reviewData.start_date), 1))
                      : (_openBlock(), _createElementBlock("i", _hoisted_54, "Datum is nog niet gepland"))
                  ]),
                  _createElementVNode("div", _hoisted_55, [
                    _cache[31] || (_cache[31] = _createElementVNode("b", null, "Aanvangstijd", -1)),
                    _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1)),
                    (_ctx.reviewData?.peer_review_day_program?.startTime)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_56, _toDisplayString(_ctx.reviewData.peer_review_day_program.startTime), 1))
                      : (_openBlock(), _createElementBlock("i", _hoisted_57, "Tijd is nog niet gepland"))
                  ])
                ]),
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("div", _hoisted_59, [
                    _cache[33] || (_cache[33] = _createElementVNode("b", null, "Locatie", -1)),
                    _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1)),
                    (_ctx.reviewData?.peer_review_day_program?.locationDetails)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_60, _toDisplayString(_ctx.reviewData.peer_review_day_program.locationDetails), 1))
                      : (_openBlock(), _createElementBlock("i", _hoisted_61, "Locatie is nog niet vastgesteld"))
                  ]),
                  (_ctx.reviewData?.peer_review_day_program?.documents.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                        _cache[36] || (_cache[36] = _createElementVNode("b", null, "Programma peer reviewdag", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviewData.peer_review_day_program.documents, (doc, d) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: d }, [
                            _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("a", {
                              href: doc.url,
                              target: "_blank"
                            }, _toDisplayString(doc.name), 9, _hoisted_63)
                          ], 64))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_BsCard, { title: "Verslaglegging" }, {
            default: _withCtx(() => [
              _createVNode(_component_BsDocumentCard, {
                title: "Concept Verslag",
                document: _ctx.reviewData?.review_report_concept?.document,
                "data-key": "review_report_concept"
              }, null, 8, ["document"]),
              _createVNode(_component_BsDocumentCard, {
                title: "Reactie Verslag",
                document: _ctx.reviewData?.review_report_feedback?.document,
                "data-key": "review_report_feedback"
              }, null, 8, ["document"]),
              _createVNode(_component_BsDocumentCard, {
                title: "Definitief Verslag",
                document: _ctx.reviewData?.review_report_definitive?.document,
                "data-key": "review_report_definitive"
              }, null, 8, ["document"])
            ]),
            _: 1
          })
        ])
      ], 2))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}