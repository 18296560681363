
import {defineComponent, PropType} from 'vue';
import {IReviewData} from '../../models/ReviewData';
import useActiveTask from '../../composeables/useActiveTask';

export default defineComponent({
    name: 'SelectPeerReviewDateField',
    props: {
        disabled: {type: Boolean, default: false},
        data: {type: Object as PropType<IReviewData>, required: true}
    },
    setup() {
        return {
            onUpdate: () => {
                useActiveTask().setSubmitTaskPrompt(true);
            }
        }
    }
})
