
import {computed, defineComponent} from 'vue';
import useActiveTask from '../../composeables/useActiveTask';
import TaskForm from '@/form/TaskForm.vue';
import useReviewData from '../../composeables/useReviewData';

export default defineComponent({
    name: 'CheckPartnershipDataTask',
    components: {TaskForm},
    setup() {
        useReviewData().load(useActiveTask().reviewId.value);

        return {
            onSubmit(form: HTMLFormElement) {
                useActiveTask().submitTask(new FormData(form));
            },
            onClick() {
                useActiveTask().setSubmitTaskPrompt(true)
            },
            inquiryUrl: computed(() => {
                return useReviewData().reviewData.value?.receiving_partnership?.inquiryUrl;
            })
        }
    }
})
