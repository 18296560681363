
import {defineComponent, PropType, ref, watch} from 'vue';
import {IFileInfo} from '../models/FileInfo';
import useActiveTask from '../composeables/useActiveTask';

export default defineComponent({
    name: 'DocumentMultiUploadField',
    props: {
        currentDocuments: {type: Array as PropType<IFileInfo[]>, default: undefined},
        name: {type: String, required: true},
        disabled: {type: Boolean, default: false}
    },
    emits: ['change'],
    setup(props, context) {
        const activeDocuments = ref(props.currentDocuments || []);

        watch(useActiveTask().errors, async (errors) => {
            if (errors.length) {
                activeDocuments.value = [];
            }
        });

        return {
            activeDocuments,
            handleDocumentUpload(e: Event & {target: {files: FileList}}) {
                if (e.target.files.length > 0) {
                    activeDocuments.value = [];

                    for (const file of e.target.files) {
                        activeDocuments.value.push({name: file.name, url: URL.createObjectURL(file)});
                    }
                }

                context.emit('change', e);
            }
        };
    }
})
