import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelMemberInput = _resolveComponent("PanelMemberInput")!
  const _component_ProgramLeaderInput = _resolveComponent("ProgramLeaderInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.panel_members_2, (member, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
        _createVNode(_component_PanelMemberInput, {
          "panel-member": member,
          "review-id": _ctx.data.id
        }, null, 8, ["panel-member", "review-id"]),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
      ], 64))
    }), 128)),
    _createVNode(_component_ProgramLeaderInput, {
      coordinators: _ctx.data.visiting_coordinators_2,
      "can-select-nobody": true,
      "review-id": _ctx.data.id,
      disabled: _ctx.disabled,
      onPanelUpdate: _ctx.onUpdate
    }, null, 8, ["coordinators", "review-id", "disabled", "onPanelUpdate"])
  ]))
}