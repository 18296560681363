import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message-heading"
}
const _hoisted_2 = { class: "message-heading__content" }
const _hoisted_3 = { class: "h6 mb-0" }
const _hoisted_4 = { class: "organization mt-1" }
const _hoisted_5 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_6 = {
  key: 0,
  class: "message-row message-view message-view--inbox no-scrollbar"
}
const _hoisted_7 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ThreadMessage = _resolveComponent("ThreadMessage")!
  const _component_TaskCard = _resolveComponent("TaskCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.thread)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_UserAvatar, {
            user: _ctx.thread.sender
          }, null, 8, ["user"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.thread.subject), 1),
            _createElementVNode("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createTextVNode(" Betreft peer review: ")),
              _createVNode(_component_RouterLink, {
                to: {name: 'PeerReviewSingle', params: {id: _ctx.thread.reviewId}}
              }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("i", { class: "oif oif-url mr-1" }, null, -1)),
                  _createTextVNode(_toDisplayString(_ctx.thread.partnershipName), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
              (_ctx.thread.partnershipName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _cache[1] || (_cache[1] = _createTextVNode("Voor: ")),
                    _createElementVNode("i", null, _toDisplayString(_ctx.thread.partnershipName), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "slide-fade-up" }, {
      default: _withCtx(() => [
        (_ctx.thread)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thread.messages, (message, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: key,
                    class: "message-view__item mb-2 w-100"
                  }, [
                    _createVNode(_component_ThreadMessage, {
                      message: message,
                      "is-first": key === 0
                    }, null, 8, ["message", "is-first"])
                  ]))
                }), 128)),
                (_ctx.thread.task)
                  ? (_openBlock(), _createBlock(_component_TaskCard, {
                      key: 0,
                      task: _ctx.thread.task,
                      class: "w-100"
                    }, null, 8, ["task"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}