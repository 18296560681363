import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 1,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("b", null, _toDisplayString(_ctx.panelMember.roleLabel), 1),
    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, [
          _createVNode(_component_UserAvatar, {
            user: _ctx.panelMember.user || undefined,
            class: "mr-1"
          }, null, 8, ["user"])
        ]),
        _createElementVNode("td", null, [
          (_ctx.panelMember.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(_ctx.panelMember.user.name), 1),
                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("a", {
                  href: `mailto:${_ctx.panelMember.user.email}`
                }, _toDisplayString(_ctx.panelMember.user.email), 9, _hoisted_3),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                (_ctx.panelMember.user.linkedInUrl)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: _ctx.panelMember.user.linkedInUrl,
                      target: "_blank"
                    }, "LinkedIn", 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("i", _hoisted_5, "Dit panellid is nog niet gekozen"))
        ])
      ])
    ])
  ], 64))
}