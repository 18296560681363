import {htmlDecode} from '../helpers/ZodHelper';
import {z} from 'zod';

export const TaskSchema = z.object({
    id: z.number(),
    reviewId: z.number(),
    createdAt: z.string(),
    isComplete: z.boolean(),
    title: z.string().transform(htmlDecode),
    partnershipName: z.string().transform(htmlDecode),
    partnershipShortName: z.string().transform(htmlDecode),
    recipientPartnershipName: z.string(),
    recipientPartnershipId: z.number().optional()
});

export type ITask = z.infer<typeof TaskSchema>;