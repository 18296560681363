import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = {
  key: 1,
  class: "peer-review-visitor-select"
}
const _hoisted_3 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipCard = _resolveComponent("PartnershipCard")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("section", {
    class: "free-peer-review-visitors-select px-3",
    onScroll: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)))
  }, [
    (_ctx.educationSectorOptions)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          ref: "filterForm",
          class: "py-3",
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("label", null, [
            _cache[7] || (_cache[7] = _createTextVNode(" Sector: ")),
            _createElementVNode("select", {
              class: "form-control",
              name: "educationSector",
              onChange: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changeFilters && _ctx.changeFilters(...args)), ["prevent"]))
            }, [
              _cache[5] || (_cache[5] = _createElementVNode("option", { value: "" }, "Alle school types", -1)),
              _cache[6] || (_cache[6] = _createTextVNode("  ")),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.educationSectorOptions, (typeLabel, typeId) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: typeId,
                  value: typeId
                }, _toDisplayString(typeLabel), 9, _hoisted_1))
              }), 128))
            ], 32)
          ]),
          _cache[11] || (_cache[11] = _createTextVNode("  ")),
          _createElementVNode("label", null, [
            _cache[9] || (_cache[9] = _createTextVNode(" Aantal studenten: ")),
            _createElementVNode("select", {
              class: "form-control",
              name: "size",
              onChange: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changeFilters && _ctx.changeFilters(...args)), ["prevent"]))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("option", { value: "" }, "Alle", -1),
              _createTextVNode("  "),
              _createElementVNode("option", { value: "l" }, "Meer dan 250", -1),
              _createTextVNode("  "),
              _createElementVNode("option", { value: "s" }, "Minder dan of exact 250", -1),
              _createTextVNode("  ")
            ]), 32)
          ]),
          _cache[12] || (_cache[12] = _createTextVNode("  ")),
          _createElementVNode("label", null, [
            _cache[10] || (_cache[10] = _createTextVNode("Zoeken: ")),
            _createElementVNode("input", {
              class: "form-control",
              name: "search",
              type: "text",
              onInput: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changeFilters && _ctx.changeFilters(...args)), ["prevent"]))
            }, null, 32)
          ])
        ], 544))
      : _createCommentVNode("", true),
    (_ctx.partnerPages)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerPages, (partners, keyA) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: keyA }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(partners, (partnership, keyB) => {
                return (_openBlock(), _createBlock(_component_PartnershipCard, {
                  key: keyB,
                  partnership: partnership,
                  "selectable-as": _ctx.partnershipType
                }, null, 8, ["partnership", "selectable-as"]))
              }), 128))
            ], 64))
          }), 128)),
          (_ctx.isLoadingPages)
            ? (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.noPartnersLeft)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Dit is alles wat er is."))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 32))
}