import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("label", null, "Peer Review Datum:", -1)),
    _createElementVNode("input", {
      type: "date",
      class: "form-control",
      name: "start_date",
      value: _ctx.data.start_date,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onUpdate && _ctx.onUpdate(...args)))
    }, null, 40, _hoisted_2)
  ]))
}