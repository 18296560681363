import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "list-unstyled timeslot-selector"
}
const _hoisted_2 = { class: "timeslot-selector__input" }
const _hoisted_3 = ["id", "value", "disabled", "checked"]
const _hoisted_4 = { class: "timeslot-selector__activity-indicator" }
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "timeslot-selector__name" }
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "timeslot-selector__count" }
const _hoisted_9 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.timeslots)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeslots, (timeslot, key) => {
          return (_openBlock(), _createElementBlock("li", {
            key: key,
            class: _normalizeClass([_ctx.getBracketClass(timeslot.count), "timeslot-selector__item"])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("input", {
                id: `timeslot_${timeslot.id}`,
                name: "timeslot",
                class: "timeslot-input",
                type: "radio",
                value: timeslot.id,
                disabled: _ctx.disabled,
                checked: timeslot.id === _ctx.data.timeslot?.id,
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
              }, null, 40, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", {
                for: `timeslot_${timeslot.id}`,
                class: "timeslot-label"
              }, [
                (_ctx.getActivityStatus(timeslot.count) === 'busy')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[1] || (_cache[1] = _createElementVNode("svg", {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        x: "0",
                        y: "0",
                        viewBox: "0 0 46 32",
                        "xml:space": "preserve"
                      }, [
                        _createElementVNode("path", {
                          d: "M8.2 10H5.8c-3 0-5.4 2.4-5.4 5.4v6.4L2.8 23l.6 8.6h7.1l.6-8.6 2.4-1.2v-6.4c.1-3-2.3-5.4-5.3-5.4zm4.2 11L10 22.2l-.6 8.2H4.6L4 22.2 1.6 21v-5.6c0-2.3 1.9-4.2 4.2-4.2h2.6c2.2.1 4 2 4 4.2V21zm11.8-11h-2.4c-3 0-5.4 2.4-5.4 5.4v6.4l2.4 1.2.6 8.6h7.1l.6-8.6 2.4-1.2v-6.4c.1-3-2.3-5.4-5.3-5.4zm4.2 11L26 22.2l-.6 8.2h-4.9l-.5-8.2-2.4-1.2v-5.6c0-2.3 1.9-4.2 4.2-4.2h2.6c2.2.1 4 2 4 4.2V21zm11.8-11h-2.4c-3 0-5.4 2.4-5.4 5.4v6.4l2.4 1.2.6 8.6h7.1l.6-8.6 2.4-1.2v-6.4c.1-3-2.3-5.4-5.3-5.4zm4.2 11L42 22.2l-.6 8.2h-4.9l-.5-8.2-2.4-1.2v-5.6c0-2.3 1.9-4.2 4.2-4.2h2.6c2.2.1 4 2 4 4.2V21zM7 .4C4.7.4 2.8 2.3 2.8 4.6S4.7 8.8 7 8.8s4.2-1.9 4.2-4.2S9.3.4 7 .4zm0 7.2c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zM23 .4c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2S25.3.4 23 .4zm0 7.2c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zM39 .4c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2S41.3.4 39 .4zm0 7.2c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z",
                          fill: "#c20000"
                        })
                      ], -1)),
                      _cache[2] || (_cache[2] = _createElementVNode("span", null, "Druk", -1))
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.getActivityStatus(timeslot.count) === 'average')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _cache[3] || (_cache[3] = _createElementVNode("svg", {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        x: "0",
                        y: "0",
                        viewBox: "0 0 46 32",
                        "xml:space": "preserve"
                      }, [
                        _createElementVNode("path", {
                          d: "M8.2 10c3 0 5.4 2.4 5.4 5.4v6.4L11.2 23l-.6 8.6H3.4L2.8 23 .4 21.8v-6.4c0-3 2.4-5.4 5.4-5.4h2.4zm16 0c3 0 5.4 2.4 5.4 5.4v6.4L27.2 23l-.6 8.6h-7.1l-.7-8.6-2.4-1.2v-6.4c0-3 2.4-5.4 5.4-5.4h2.4zm-16 1.2H5.8c-2.3 0-4.2 1.9-4.2 4.2V21L4 22.2l.6 8.2h4.9l.6-8.2 2.4-1.2v-5.6c0-2.2-1.8-4.1-4-4.2h-.3zm16 0h-2.4c-2.3 0-4.2 1.9-4.2 4.2V21l2.4 1.2.6 8.2h4.9l.6-8.2 2.4-1.2v-5.6c0-2.2-1.8-4.1-4-4.2h-.3zM7 .4c2.3 0 4.2 1.9 4.2 4.2S9.3 8.8 7 8.8 2.8 6.9 2.8 4.6 4.7.4 7 .4zm16 0c2.3 0 4.2 1.9 4.2 4.2S25.3 8.8 23 8.8s-4.2-1.9-4.2-4.2S20.7.4 23 .4zM7 1.6c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm16 0c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z",
                          fill: "#d0c143"
                        })
                      ], -1)),
                      _cache[4] || (_cache[4] = _createElementVNode("span", null, "Gemiddeld", -1))
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.getActivityStatus(timeslot.count) === 'quiet')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _cache[5] || (_cache[5] = _createElementVNode("svg", {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        x: "0",
                        y: "0",
                        viewBox: "0 0 46 32",
                        "xml:space": "preserve"
                      }, [
                        _createElementVNode("path", {
                          d: "M8.2 10c3 0 5.4 2.4 5.4 5.4v6.4L11.2 23l-.6 8.6H3.4L2.8 23 .4 21.8v-6.4c0-3 2.4-5.4 5.4-5.4h2.4zm0 1.2H5.8c-2.3 0-4.2 1.9-4.2 4.2V21L4 22.2l.6 8.2h4.9l.6-8.2 2.4-1.2v-5.6c0-2.2-1.8-4.1-4-4.2h-.3zM7 .4c2.3 0 4.2 1.9 4.2 4.2S9.3 8.8 7 8.8 2.8 6.9 2.8 4.6 4.7.4 7 .4zm0 1.2c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z",
                          fill: "#00911d"
                        })
                      ], -1)),
                      _cache[6] || (_cache[6] = _createElementVNode("span", null, "Rustig", -1))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", {
                for: `timeslot_${timeslot.id}`,
                class: "timeslot-label"
              }, _toDisplayString(timeslot.startDateFormatted) + " - " + _toDisplayString(timeslot.endDateFormatted), 9, _hoisted_7)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", {
                for: `timeslot_${timeslot.id}`,
                class: "timeslot-label"
              }, _toDisplayString(timeslot.count), 9, _hoisted_9)
            ])
          ], 2))
        }), 128))
      ]))
    : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
}