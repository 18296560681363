import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mx-2" }
const _hoisted_4 = { class: "mx-2" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "d-flex justify-content-center" }
const _hoisted_7 = ["name", "disabled", "checked"]
const _hoisted_8 = { class: "d-flex justify-content-center" }
const _hoisted_9 = ["name", "disabled", "checked"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_ctx.data.visiting_partnerships.first && _ctx.data.visiting_partnerships.second)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_ctx.reviewerRoles)
          ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
              _createElementVNode("tr", null, [
                _cache[1] || (_cache[1] = _createElementVNode("td", null, null, -1)),
                _createElementVNode("td", null, [
                  _createElementVNode("b", _hoisted_3, _toDisplayString(_ctx.data.visiting_partnerships.first.label), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("b", _hoisted_4, _toDisplayString(_ctx.data.visiting_partnerships.second.label), 1)
                ]),
                (Object.keys(_ctx.panelMembers).length && !_ctx.disabled)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_5, _cache[0] || (_cache[0] = [
                      _createElementVNode("b", { class: "mx-2" }, "Member", -1)
                    ])))
                  : _createCommentVNode("", true)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviewerRoles, (roleLabel, roleKey) => {
                return (_openBlock(), _createElementBlock("tr", { key: roleKey }, [
                  _createElementVNode("td", null, _toDisplayString(roleLabel), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("label", _hoisted_6, [
                      _createElementVNode("input", {
                        value: "1",
                        name: `${roleKey}`,
                        type: "radio",
                        disabled: (_ctx.disabled || _ctx.panelMembers[roleKey]?.user) && _ctx.data.panel_member_roles?.[roleKey] !== 1,
                        checked: _ctx.data.panel_member_roles?.[roleKey] === 1
                      }, null, 8, _hoisted_7)
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("label", _hoisted_8, [
                      _createElementVNode("input", {
                        value: "2",
                        name: `${roleKey}`,
                        type: "radio",
                        disabled: (_ctx.disabled || _ctx.panelMembers[roleKey]?.user) && _ctx.data.panel_member_roles?.[roleKey] !== 2,
                        checked: _ctx.data.panel_member_roles?.[roleKey] === 2
                      }, null, 8, _hoisted_9)
                    ])
                  ]),
                  (Object.keys(_ctx.panelMembers).length && !_ctx.disabled)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_10, [
                        (_ctx.panelMembers[roleKey].user)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.panelMembers[roleKey].user.name), 1))
                          : (_openBlock(), _createElementBlock("i", _hoisted_12, "Niet toegewezen"))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _cache[2] || (_cache[2] = _createElementVNode("h5", null, "U kunt deze taak pas doen nadat beiden partnerschappen uw uitnodigen hebben geaccepteerd.", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
      ], 64))
}