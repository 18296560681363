import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-md-4" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = ["name", "disabled"]
const _hoisted_5 = { class: "col-12 col-md-5" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = ["name", "disabled"]
const _hoisted_8 = {
  key: 0,
  class: "col-12 col-md-3"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 1,
  class: "col-12 col-md-3 form-group"
}
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleForm = _resolveComponent("SimpleForm")!

  return (_openBlock(), _createBlock(_component_SimpleForm, {
    target: `wp-json/api/${_ctx.action}-panel-member/${_ctx.reviewId}/${_ctx.panelMember.panelRole}`,
    alerts: 'html',
    "success-text": "",
    onSubmit: _ctx.onSubmit,
    onResponse: _ctx.onResponse
  }, {
    default: _withCtx(() => [
      _createElementVNode("h6", null, _toDisplayString(_ctx.panelMember.roleLabel), 1),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[4] || (_cache[4] = _createElementVNode("label", null, "Naam:", -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.member.name) = $event)),
              type: "text",
              class: "form-control",
              name: `${_ctx.panelMember.panelRole}_name`,
              disabled: _ctx.disabled || _ctx.isBusy || _ctx.member.locked,
              maxlength: "250"
            }, null, 8, _hoisted_4), [
              [_vModelText, _ctx.member.name]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("label", null, "E-mailadres:", -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.member.email) = $event)),
              type: "email",
              class: "form-control",
              name: `${_ctx.panelMember.panelRole}_email`,
              disabled: _ctx.disabled || _ctx.isBusy || _ctx.member.locked,
              maxlength: "100"
            }, null, 8, _hoisted_7), [
              [_vModelText, _ctx.member.email]
            ])
          ])
        ]),
        (_ctx.member.locked)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("button", {
                class: "btn btn-outline-danger mt-4",
                disabled: _ctx.disabled || _ctx.isBusy,
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removePanelMember && _ctx.removePanelMember(...args)))
              }, " Verwijderen ", 8, _hoisted_9)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "btn btn-outline-primary mt-4",
                disabled: _ctx.disabled || _ctx.isBusy,
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addPanelMember && _ctx.addPanelMember(...args)))
              }, " Uitnodigen ", 8, _hoisted_11)
            ]))
      ])
    ]),
    _: 1
  }, 8, ["target", "onSubmit", "onResponse"]))
}