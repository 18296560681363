import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentUploadField = _resolveComponent("DocumentUploadField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("label", null, "Definitief Verslag:", -1)),
      _createVNode(_component_DocumentUploadField, {
        name: "review_report_definitive",
        "current-document": _ctx.data.review_report_definitive?.document,
        disabled: _ctx.disabled
      }, null, 8, ["current-document", "disabled"])
    ])
  ]))
}