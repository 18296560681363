import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row no-gutters w-100" }
const _hoisted_2 = { class: "col-12 col-xl-8" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card-title h6 mb-0" }
const _hoisted_5 = {
  key: 0,
  class: "card-text"
}
const _hoisted_6 = { class: "col-12 col-xl-4" }
const _hoisted_7 = { class: "card-footer text-right" }
const _hoisted_8 = { class: "card-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipInviteStatusBadge = _resolveComponent("PartnershipInviteStatusBadge")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card card--partner mb-2 cursor-pointer", {'unavailable': _ctx.unavailableReason && _ctx.selectableAs}]),
    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.requestDetails && _ctx.requestDetails(...args)), ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.partnership)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.partnership.label), 1),
                _createElementVNode("b", null, "[" + _toDisplayString(_ctx.partnership.educationSector) + "] " + _toDisplayString(_ctx.partnership.city), 1),
                (_ctx.unavailableReason && _ctx.selectableAs)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.unavailableReason?.label), 1))
                  : (_ctx.inviteStatus)
                    ? (_openBlock(), _createBlock(_component_PartnershipInviteStatusBadge, {
                        key: 1,
                        "invite-status": _ctx.inviteStatus,
                        partnership: _ctx.partnership
                      }, null, 8, ["invite-status", "partnership"]))
                    : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, [
                  (!_ctx.unavailableReason && _ctx.selectableAs)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "button",
                        class: "btn btn-primary mb-1",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSelect && _ctx.onSelect(...args)), ["stop"]))
                      }, " Selecteer "))
                    : _createCommentVNode("", true),
                  (_ctx.unavailableReason?.canReroll && _ctx.selectableAs === _ctx.PartnershipSelectionType.SHORTLIST)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        type: "button",
                        class: "btn btn-dark mb-1",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRequestNewEntry && _ctx.onRequestNewEntry(...args)), ["stop"]))
                      }, " Geef mij een nieuwe keuze "))
                    : _createCommentVNode("", true),
                  _cache[3] || (_cache[3] = _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-secondary mb-1"
                  }, " Bekijk ", -1))
                ])
              ])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 2))
}