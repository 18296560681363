import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "header-wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header__inner" }
const _hoisted_4 = { class: "navigation-drawer" }
const _hoisted_5 = { class: "header__menu-navigation" }
const _hoisted_6 = { class: "list-unstyled menu-navigation-list mb-0" }
const _hoisted_7 = { class: "menu-option" }
const _hoisted_8 = { class: "list-icon" }
const _hoisted_9 = { class: "icon-layers" }
const _hoisted_10 = { class: "menu-option" }
const _hoisted_11 = { class: "list-icon" }
const _hoisted_12 = { class: "icon-layers" }
const _hoisted_13 = {
  key: 0,
  class: "menu-option"
}
const _hoisted_14 = { class: "list-icon" }
const _hoisted_15 = { class: "icon-layers" }
const _hoisted_16 = { class: "d-none d-md-block mt-md-auto w-100" }
const _hoisted_17 = { class: "list-unstyled link-list mb-0" }
const _hoisted_18 = { class: "link-list__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_NewMessagesBadge = _resolveComponent("NewMessagesBadge")!
  const _component_NewTasksBadge = _resolveComponent("NewTasksBadge")!
  const _component_CurrentUserAvatar = _resolveComponent("CurrentUserAvatar")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createVNode(_component_RouterLink, {
                  to: {name: 'PeerReviews'},
                  class: "menu-navigation-list__link"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_8, [
                      _createElementVNode("span", _hoisted_9, [
                        _createVNode(_component_Icon, {
                          icon: "blockList",
                          class: "icon-layers-bottom"
                        }),
                        _createVNode(_component_Icon, {
                          icon: "blockListItem",
                          class: "icon-layers-top"
                        })
                      ])
                    ]),
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "list-title" }, "Peer reviews", -1))
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createVNode(_component_RouterLink, {
                  to: {name: 'Inbox'},
                  class: "menu-navigation-list__link"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, [
                        _createVNode(_component_Icon, {
                          icon: "blockList",
                          class: "icon-layers-bottom"
                        }),
                        _createVNode(_component_Icon, {
                          icon: "blockFile",
                          class: "icon-layers-top icon-layers-blockFile"
                        })
                      ])
                    ]),
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "list-title" }, "Berichten", -1)),
                    _createVNode(_component_NewMessagesBadge)
                  ]),
                  _: 1
                })
              ]),
              (_ctx.user?.hasWriteAccess)
                ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                    _createVNode(_component_RouterLink, {
                      to: {name: 'Tasks'},
                      class: "menu-navigation-list__link"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_14, [
                          _createElementVNode("span", _hoisted_15, [
                            _createVNode(_component_Icon, {
                              icon: "blockFlower",
                              class: "icon-layers-bottom"
                            }),
                            _createVNode(_component_Icon, {
                              icon: "blockCheck",
                              class: "icon-layers-top icon-layers-blockCheck"
                            })
                          ])
                        ]),
                        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "list-title" }, "Taken", -1)),
                        _createVNode(_component_NewTasksBadge)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("ul", _hoisted_17, [
            _createElementVNode("li", _hoisted_18, [
              _createVNode(_component_RouterLink, {
                to: {name: 'Stappenplan'},
                class: "menu-navigation-list__link"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Stappenplan & contact")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _createVNode(_component_CurrentUserAvatar)
      ])
    ])
  ]))
}