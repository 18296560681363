
import {defineComponent, PropType} from 'vue';
import {IInviteRequest} from '../../models/InviteRequest';
import LimitedTextArea from '@/form/LimitedTextArea.vue';

export default defineComponent({
    name: 'InviteField',
    components: {LimitedTextArea},
    emits: ['change'],
    props: {
        invite: {type: Object as PropType<IInviteRequest>, required: true},
        disabled: {type: Boolean, default: false}
    },
    setup(props, context) {
        return {
            onChange() {
                context.emit('change');
            }
        }
    }
})
